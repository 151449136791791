import { useState, useEffect } from 'react'

import Page from '@/Page.jsx'
import { api, useUser } from '@/common'

import ButtonAppAdd from '@/ButtonAppAdd.jsx'
import ButtonTermAdd from '@/ButtonTermAdd.jsx'
import ButtonAppDelete from '@/ButtonAppDelete.jsx'
import ButtonTermDelete from '@/ButtonTermDelete.jsx'
import ButtonAppReport from '@/ButtonAppReport.jsx'
import ButtonUpdate from '@/ButtonUpdate.jsx'

export default function PageList () {
  const [apps, appsSet] = useState([])
  const [user] = useUser()
  const [allTerms, allTermsSet] = useState([])

  const updateApps = async () => {
    const a = await api.get('/app')
    appsSet(a)
    const t = []
    for (const app of a) {
      for (const {term} of app.terms) {
        if (!t.includes(term)) {
          t.push(term)
        }
      }
    }
    allTermsSet(t)
  }

  useEffect(() => {
    if (user) {
      updateApps()
    }
  }, [user])

  return (
    <Page>
      <div className='flex flex-row gap-2'>
        <ButtonAppAdd className='btn-primary' onSubmit={updateApps} />
        {!!apps?.length && (<ButtonTermAdd className='btn-secondary' apps={apps} onSubmit={updateApps} />)}
        {!!apps?.length && (<ButtonAppReport className='btn-accent'>Get Report for All</ButtonAppReport>)}
      </div>
      <div className='flex flex-col gap-2 mt-2'>
        {apps.map((app, i) => (
          <div key={i} tabIndex={i} className='border border-base-300 p-4'>
            <div className='flex flex-row gap-2 items-center mb-2'>
              <img src={app.icon} alt={app.title} className='w-8' />
              <span><a href={`https://apps.apple.com/${app.country.toLowerCase()}/app/id${app.store_id}?l=${app.language.split('_')[0]}`}>{app.title}</a></span>
            </div>
            <div className='text-sm'>
              <div>ID: {app.store_id}</div>
              <div>Gummi ID: {app.id}</div>
              <div>Country: {app.country}</div>
              <div>Language: {app.language}</div>
              {!!app.terms?.length && (
                <table className='table table-zebra mt-2'>
                  <thead>
                    <tr>
                      <th>Term</th>
                      <th>Rank</th>
                      <th>Last Searched</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {app.terms.map((t, i) => (
                      <tr key={i} className='hover'>
                        <td>{t.term}</td>
                        <td>{t.position}</td>
                        <td>{t.date}</td>
                        <td className='flex'>
                          <ButtonTermDelete app={app} term={t} onSubmit={updateApps} className='btn-circle hover:btn-error btn-sm' />
                          <ButtonUpdate className='btn-circle hover:btn-success btn-sm' app={app} term={t} onSubmit={updateApps} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className='flex gap-2 flex-row mt-2'>
                <ButtonTermAdd title={`Add terms to ${app.title}`} className='btn-outline btn-secondary btn-circle' onSubmit={updateApps} apps={[app]} children='' />
                <ButtonAppDelete app={app} className='btn-outline btn-circle btn-error' onSubmit={updateApps} />
                <ButtonAppReport app={app} className='btn-outline btn-circle btn-accent' />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Page>
  )
}
