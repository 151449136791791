import { useState, useCallback } from 'react'
import { IconRefreshAlert } from '@tabler/icons-react'
import { api } from '@/common'

export default function ButtonUpdate({ app, term, className, onSubmit }) {
  const [disabled, disabledSet] = useState(false)
  const [toast, toastSet] = useState(false)

  const setToast = useCallback((type, message) => {
    toastSet({type, message})
    setTimeout(() => toastSet(false), 2000)
  })

  const handleClick = useCallback(async e => {
    e.preventDefault()
    disabledSet(true)
    try {
      const position = await api.get(`/update/${encodeURIComponent(app.id)}/${encodeURIComponent(term.term)}`)
      if (position === 0){
        setToast('success', `${position}: ${app.title} in ${app.country}/${app.language}: not found for "${term.term}"`)
      } else {
        setToast('success', `${position}: ${app.title} in ${app.country}/${app.language}: ranked ${position} for "${term.term}"`)
      }
    } catch(e) {
      setToast('error', `${app.title} in ${app.country}/${app.language}: ${e.message}`)
    }
    
    await onSubmit()
    disabledSet(false)
  })

  let tooltipClasses = ''
  if (toast) {
    tooltipClasses=`tooltip tooltip-open tooltip-${toast.type}`
  }

  return (
    <div className={tooltipClasses} data-tip={toast?.message}>
      <button title={`Manually update "${term.term}" from ${app.title}`} className={`btn ${className}`} disabled={disabled} onClick={handleClick}>
        <IconRefreshAlert />
      </button>
    </div>
  )
}